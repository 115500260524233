import React from "react";
import { ContinuousDevelopmentIcon } from "../../components/layout/icons/ContinuousDevelopmentIcon";
import { NewFeatureIcon } from "../../components/layout/icons/NewFeatureIcon";
import { LiveOperationsIcon } from "../../components/layout/icons/LiveOperationsIcon";
import { CustomerServiceIcon } from "../../components/layout/icons/CustomerServiceIcon";
import { GameDevelopmentServiceData } from "./GameDesignData";

export const continuousDevelopmentPageData: GameDevelopmentServiceData = {
    metaPageTitle: "Continuous Development - iDream Interactive",
    metaKeywords: "continuous development, video game events, liveops, unity engine",
    metaDescription: "We can build out the new game features, manage your customer support and even build out special events with live operations.",
    title: "Continuous Development",
    subtitle: "We can build out new game features, manage your customer support and even build out special events with live operations.",
    icon: <ContinuousDevelopmentIcon fill="#ffffff" />,
    services: [
        {
            title: "New Feature Development",
            description: (<>
                <p>New features may introduce more levels, dungeons, characters or even expanded game mechanics. New Feature Development is very similar to the full-cycle game production process, but on a smaller scale.  If we’ve built the original game for you, adding additional features makes it that much easier.</p>
            </>),
            icon: <NewFeatureIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Person coding features",
        },
        {
            title: "Live Operations",
            description: (<>
                <p>Live Operations are all about on-going support once your game has gone live. We consider what happens after your game is released, just as important as the initial development stages. We believe in keeping a game fresh by adding in timed events, updating artwork, fixing bugs, balancing economy and listening to our users on their pain points.</p>
            </>),
            icon: <LiveOperationsIcon fill="#03041d" />,
            image: "",
            imageAlt: "Man announcing new live events",
        },
        {
            title: "Customer Service",
            description: (<>
                <p>Keeping your customers happy and engaged is key to your products success.  Having an easy way for them to speak their mind or report a bug is critical. We do this by having simple portals where users can submit their issues and feedback. We are commited to responding within 1 business day to any issue and resolving it as quickly as possible.</p>
            </>),
            icon: <CustomerServiceIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Man answering a support call",
        },
    ]
}