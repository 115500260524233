import React from "react";

interface Props {
    fill: string;
}

export const ContinuousDevelopmentIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} className="icon">
            <path d="M4.3,13.5C1.9,13.5,0,11.5,0,9c0-2.5,1.9-4.5,4.3-4.5c1.2,0,2.2,0.5,3,1.3L8.6,7L6.8,8.7l-1.1-1
            C5.3,7.2,4.8,7,4.3,7C3.2,7,2.4,7.9,2.4,9c0,1.1,0.9,2,1.9,2c0.5,0,1-0.2,1.4-0.6l1-0.9h0l4-3.7c0.8-0.8,1.9-1.3,3-1.3
            c2.4,0,4.3,2,4.3,4.5c0,2.5-1.9,4.5-4.3,4.5c-1.2,0-2.2-0.5-3-1.3L9.4,11l1.8-1.6l1.1,1c0.4,0.4,0.9,0.6,1.4,0.6
            c1.1,0,1.9-0.9,1.9-2c0-1.1-0.9-2-1.9-2c-0.5,0-1,0.2-1.4,0.6l-1.6,1.5L9,10.7l0,0l-1.7,1.5C6.5,13,5.4,13.5,4.3,13.5z"/>
        </svg>
    )
}