import React from "react";

interface Props {
    fill: string;
}

export const CustomerServiceIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M25.4,9.8V8.3c0-4.3-3.5-7.8-7.8-7.8h-6.2C7.1,0.5,3.6,4,3.6,8.3v1.6c-1.7,0-3.1,1.4-3.1,3.1v4.7c0,1.7,1.4,3.1,3.1,3.1
            c1.7,0,3.1-1.4,3.1-3.1v-4.7c0,0,0,0,0,0V8.3c0-2.6,2.1-4.7,4.7-4.7h6.2c2.6,0,4.7,2.1,4.7,4.7v3.6v1.1c0,0,0,0,0,0v4.7
            c0,0,0,0,0,0v1.6c0,2.6-2.1,4.7-4.7,4.7h-0.4c-0.5-0.9-1.5-1.6-2.7-1.6c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1
            c1.1,0,2.1-0.6,2.7-1.6h0.4c3.8,0,6.9-2.7,7.6-6.2c0.1,0,0.1,0,0.2,0c1.7,0,3.1-1.4,3.1-3.1v-4.7C28.5,11.2,27.1,9.8,25.4,9.8z"/>
        </svg>
    )
}