import React from "react";
import Img, { FluidObject } from "gatsby-image";
import { Button, ButtonConfig } from "./Button";

interface Props {
    icon?: JSX.Element,
    title: string,
    description: JSX.Element,
    image: FluidObject,
    imageAlt: string,
    isSoftware?: boolean,
    buttonConfig?: ButtonConfig,
    className?: string,
}


export const LeftContainer: React.FC<Props> = ({ icon, title, description, image, imageAlt, isSoftware, buttonConfig, className = "" }) => {
    return (
        <div className={`left-container ${isSoftware ? "software-info-container" : "info-container"} ${className}`}>
            <div className="image-container large-show" data-sal="slide-right">
                <Img fluid={image} alt={imageAlt} />
            </div>
            <div className="info" data-sal="slide-left">
                <div className="heading">
                    {icon &&
                        icon
                    }
                    <h2>{title}</h2>
                </div>
                {description}
                {buttonConfig &&
                    <Button
                        buttonConfig={buttonConfig}
                    />
                }
            </div>
            <div className="image-container small-show" data-sal="slide-right">
                <Img fluid={image} alt={imageAlt} />
            </div>
        </div>
    )
}