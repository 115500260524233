import React from "react";

interface Props {
    fill: string;
}

export const NewFeatureIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M27.9,15.8c0.8-0.7,0.8-1.9,0-2.6l-1.2-1c-0.6-0.5-0.8-1.2-0.5-1.9l0.6-1.4c0.4-1-0.2-2.1-1.3-2.3L24,6.4
            c-0.7-0.1-1.3-0.7-1.4-1.4l-0.2-1.5c-0.2-1.1-1.3-1.7-2.3-1.3l-1.4,0.6C18,3,17.2,2.8,16.8,2.2l-1-1.2c-0.7-0.8-1.9-0.8-2.6,0
            l-1,1.2C11.8,2.8,11,3,10.3,2.8L8.9,2.2c-1-0.4-2.1,0.2-2.3,1.3L6.4,5C6.3,5.7,5.7,6.3,5,6.4L3.5,6.7C2.5,6.8,1.8,7.9,2.2,8.9
            l0.6,1.4C3,11,2.8,11.8,2.2,12.2l-1.2,1c-0.8,0.7-0.8,1.9,0,2.6l1.2,1C2.8,17.2,3,18,2.8,18.7l-0.6,1.4c-0.4,1,0.2,2.1,1.3,2.3
            L5,22.6c0.7,0.1,1.3,0.7,1.4,1.4l0.2,1.5c0.2,1.1,1.3,1.7,2.3,1.3l1.4-0.6c0.7-0.3,1.4-0.1,1.9,0.5l1,1.2c0.7,0.8,1.9,0.8,2.6,0
            l1-1.2c0.5-0.6,1.2-0.8,1.9-0.5l1.4,0.6c1,0.4,2.1-0.2,2.3-1.3l0.2-1.5c0.1-0.7,0.7-1.3,1.4-1.4l1.5-0.2c1.1-0.2,1.7-1.3,1.3-2.3
            l-0.6-1.4c-0.3-0.7-0.1-1.4,0.5-1.9L27.9,15.8z M23.2,19.5c-1.9,0.4-3.3,1.8-3.7,3.7c-0.5-0.2-1-0.2-1.5-0.2
            c-1.4,0-2.7,0.6-3.6,1.6c-0.9-1-2.2-1.6-3.6-1.6c-0.5,0-1,0.1-1.5,0.2c-0.4-1.9-1.8-3.3-3.7-3.7c0.6-1.8,0.1-3.8-1.4-5
            c1.4-1.3,1.9-3.2,1.4-5c1.9-0.4,3.3-1.8,3.7-3.7c0.5,0.2,1,0.2,1.5,0.2c1.4,0,2.7-0.6,3.6-1.6c0.9,1,2.2,1.6,3.6,1.6
            c0.5,0,1-0.1,1.5-0.2c0.4,1.9,1.8,3.3,3.7,3.7c-0.6,1.8-0.1,3.8,1.4,5C23.2,15.8,22.7,17.7,23.2,19.5z"/>
            <path d="M14.5,16.1L14.5,16.1c-0.9,0-1.6-0.7-1.6-1.6V9.8c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v4.7
		    C16.1,15.3,15.4,16.1,14.5,16.1z"/>
            <path d="M14.5,20.8L14.5,20.8c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6l0,0
		    C16.1,20.1,15.4,20.8,14.5,20.8z"/>
        </svg>
    )
}