import React from "react";
import { Button, ButtonColour, ButtonSize } from "./Button";

interface Props {
    logoElement: JSX.Element;
    title?: string,
    subtitle?: string,
    icon?: JSX.Element,
    ctaLabel?: string,
    ctaLink?: string,
    ctaAriaLabel?: string,
    className?: string,
    externalLink?: boolean;
}

export const HeaderInfo: React.FC<Props> = ({ logoElement, title, subtitle, icon, ctaLabel, ctaLink, ctaAriaLabel, className = "", children, externalLink }) => {
    return (
        <div className={`header-info-container ${className}`}>
            {icon &&
                icon
            }
            <div className="header-info">
                {logoElement && <>{logoElement}</>}
                {title && <h1 className="title">{title}</h1>}
                {subtitle && <h2 className="subtitle">{subtitle}</h2>}
                {ctaLink && ctaLabel &&
                    <Button
                        buttonConfig={
                            {
                                id: "blueGradient",
                                ctaLabel,
                                ctaLink,
                                size: ButtonSize.LARGE,
                                colour: ButtonColour.WHITE_DARK,
                                ariaLabel: ctaAriaLabel,
                                externalLink: externalLink,
                            }
                        }
                    />
                }
            </div>
            {children}
        </div>
    )
}