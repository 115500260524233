import React from "react"
import Img from "gatsby-image";
import Layout from "../../components/layout/Layout";
import { graphql } from "gatsby"
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../../components/layout/ParallaxHeader";
import { HeaderInfo } from "../../components/layout/HeaderInfo";
import { HeaderImageContainer } from "../../components/layout/HeaderImageContainer";
import { Container } from "../../components/layout/Container";
import { ContainerColour, DiagonalContainer, ContainerSize } from "../../components/layout/DiagonalContainer";
import { LeftContainer } from "../../components/layout/LeftContainer";
import { RightContainer } from "../../components/layout/RightContainer";
import { continuousDevelopmentPageData } from "../../content/pageData/ContinuousDevelopmentPageData";
import { ButtonColour, ButtonSize, Button } from "../../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        robotMobile: {
            childImageSharp: any;
        }
        robot: {
            childImageSharp: any;
        }
        newFeatures: {
            childImageSharp: any;
        }
        liveOperations: {
            childImageSharp: any;
        }
        customerService: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const title = continuousDevelopmentPageData.title;
    const robotMobile = data.robotMobile.childImageSharp.fluid;
    const robot = data.robot.childImageSharp.fluid;
    const robotSources = [
        robotMobile,
        {
            ...robot,
            media: `(min-width: 768px)`,
        }
    ]

    return (
        <Layout
            pageTitle={continuousDevelopmentPageData.metaPageTitle}
            keywords={continuousDevelopmentPageData.metaKeywords}
            description={continuousDevelopmentPageData.metaDescription}
            url={"game-development/continuous-development"}
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.MEDIUM}>
                <HeaderInfo
                    title={title}
                    icon={continuousDevelopmentPageData.icon}
                    subtitle={continuousDevelopmentPageData.subtitle}
                    className="game-development-service-header continuous-development"
                />
            </ParallaxHeader>
            <HeaderImageContainer>
                <Img className="banana-boat" fluid={robotSources} alt={`Nana, Nina, and Arthur in a banana boat`} />
            </HeaderImageContainer>
            <Container className="non-diagonal-container first-container continuous-development">
                <LeftContainer
                    icon={continuousDevelopmentPageData.services[0].icon}
                    title={continuousDevelopmentPageData.services[0].title}
                    description={continuousDevelopmentPageData.services[0].description}
                    image={data.newFeatures.childImageSharp.fluid}
                    imageAlt={continuousDevelopmentPageData.services[0].imageAlt}
                />
            </Container>
            <DiagonalContainer size={ContainerSize.MEDIUM} colour={ContainerColour.ORANGE} hasRips={false} withPad={true}>
                <RightContainer
                    icon={continuousDevelopmentPageData.services[1].icon}
                    title={continuousDevelopmentPageData.services[1].title}
                    description={continuousDevelopmentPageData.services[1].description}
                    image={data.liveOperations.childImageSharp.fluid}
                    imageAlt={continuousDevelopmentPageData.services[1].imageAlt}
                />
            </DiagonalContainer>
            <Container className="non-diagonal-container continuous-development">
                <LeftContainer
                    icon={continuousDevelopmentPageData.services[2].icon}
                    title={continuousDevelopmentPageData.services[2].title}
                    description={continuousDevelopmentPageData.services[2].description}
                    image={data.customerService.childImageSharp.fluid}
                    imageAlt={continuousDevelopmentPageData.services[2].imageAlt}
                />
            </Container>
            <Container className="game-development-container centered-button">
                <Button buttonConfig={{
                    colour: ButtonColour.BLUE,
                    size: ButtonSize.LARGE,
                    ctaLabel: "Back",
                    ctaLink: "/game-development",
                    id: "blueGradient",
                    ariaLabel: "Return to Game Development"
                }}
                />
            </Container>
        </Layout>
    );
};



export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        robotMobile: file(relativePath: { eq: "assets/images/continuous-development/bananaBoat_small.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        robot: file(relativePath: { eq: "assets/images/continuous-development/bananaBoat.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        newFeatures: file(relativePath: { eq: "assets/images/continuous-development/newFeatures.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        liveOperations: file(relativePath: { eq: "assets/images/continuous-development/liveOps.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        customerService: file(relativePath: { eq: "assets/images/continuous-development/customerService.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`