import React from "react";

interface Props {
    fill: string;
}

export const LiveOperationsIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M20.1,23c0,3-2.5,5.4-5.6,5.4c-3.1,0-5.6-2.4-5.6-5.4c0-3,2.5-5.4,5.6-5.4C17.6,17.6,20.1,20,20.1,23z" />
            <path d="M14.5,9.1c-3.3,0-6.2,1.1-8.7,2.9l1.9,2.5c1.9-1.4,4.2-2.2,6.7-2.2c2.5,0,4.9,0.8,6.7,2.2l1.9-2.5
		    C20.7,10.1,17.8,9.1,14.5,9.1z"/>
            <path d="M2.5,7.6c3.3-2.5,7.5-4,12-4s8.7,1.5,12,4l1.9-2.5c-3.9-2.9-8.7-4.6-14-4.6c-5.2,0-10.1,1.7-14,4.6L2.5,7.6z" />
        </svg>
    )
}