import React from "react";

interface Props {
}

export const HeaderImageContainer: React.FC<Props> = ({ children }) => {
    return (
        <div className="header-image-container" data-sal="fade">
            {children}
        </div>
    )
}