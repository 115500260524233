import React from "react";
import Img, { FluidObject } from "gatsby-image";
import { Button, ButtonConfig } from "./Button";

interface Props {
    icon: JSX.Element,
    title: string,
    description: JSX.Element,
    image: FluidObject,
    imageAlt: string,
    isSoftware?: boolean,
    buttonConfig?: ButtonConfig,
}


export const RightContainer: React.FC<Props> = ({ icon, title, description, image, imageAlt, isSoftware, buttonConfig }) => {
    return (
        <div className={`right-container ${isSoftware ? "software-info-container" : "info-container"}`}>
            <div className="info" data-sal="slide-right" >
                <div className="heading">
                    {icon}
                    <h2>{title}</h2>
                </div>
                {description}
                {buttonConfig &&
                    <Button
                        buttonConfig={buttonConfig}
                    />
                }
            </div>
            <div className="image-container" data-sal="slide-left" >
                <Img fluid={image} alt={`${imageAlt} image`} />
            </div>
        </div>
    )
}